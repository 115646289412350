import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const Callout = ({ className, children }) => {
    return (
        <div className={`c-callout${className && ` ${className}`}`}>
            {children}
        </div>
    )
}

Callout.propTypes = {
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

Callout.defaultProps = {
    className: '',
    children: '<p>Callout content goes here</p>'
}

export default Callout
